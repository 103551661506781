import { ref } from 'vue';
import { io } from 'socket.io-client';
import { useIp } from './useIp';

let socketRef = null;

export function useSocket() {
  if (!socketRef) {
    const ips = useIp();
    const socket = io(ips.ipWebSocket, {
      transports: ["websocket"],
      upgrade: false,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      randomizationFactor: 0.5,
    });
    socketRef = ref(socket);
  }
  
  return { socket: socketRef };
} 