import Vue from "vue";
import Vuetify from "vuetify/lib";

import pt from "./locales/pt";
import en from "./locales/en";

Vue.use(Vuetify);

const savedTheme = localStorage.getItem("theme") || "light";

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 600,
  },
  theme: {
    dark: savedTheme === "dark",
    themes: {
      light: {
        
      },
      dark: {
        primary: "#121212",
        secondary: "#1E1E1E",
        accent: "#BB86FC",
        error: "#CF6679",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        background: "#121212",
        white: "#FFFFFF",
      },
    },
  },
  lang: {
    locales: { pt, en },
    current: "pt",
  },
});
