<template>
    <div>
      <!-- Botão de Sino -->
      <v-btn v-if="false" @click="toggleNotifications" icon class="notification-btn" v-tooltip="'Notificações'">
        <v-icon>mdi-bell</v-icon>
        <v-badge v-if="unreadCount > 0" color="red" :content="unreadCount" overlap></v-badge>
      </v-btn>
  
      <!-- Dropdown de Notificações -->
      <v-menu
        v-model="showNotifications"
        :position-x="x"
        :position-y="y"
        offset-y
        :close-on-content-click="false"
        transition="scale-transition"
        origin="top right"
      >
        <v-card class="notification-card" :class="{ 'dark-theme': isDark }">
          <!-- Filtros de Categoria -->
<v-toolbar dense class="filter-toolbar">
    <div class="filter-scroll-container">
      <v-btn text @click="filterByCategory('Todos')" :class="{ active: activeFilter === 'Todos' }">
        Todos
      </v-btn>
      <v-btn text @click="filterByCategory('Eventos')" :class="{ active: activeFilter === 'Eventos' }">
        Eventos
      </v-btn>
      <v-btn text @click="filterByCategory('Sistema')" :class="{ active: activeFilter === 'Sistema' }">
        Sistema
      </v-btn>
      <v-btn text @click="filterByCategory('Live')" :class="{ active: activeFilter === 'Live' }">
        Live
      </v-btn>
      <v-btn text @click="filterByCategory('Video')" :class="{ active: activeFilter === 'Video' }">
        Video
      </v-btn>
    </div>
  </v-toolbar>
  
  <!-- Filtros de Urgência -->
  <v-toolbar dense class="urgency-toolbar">
    <div class="filter-scroll-container">
      <v-btn text @click="filterByUrgency('Todas')" :class="{ active: activeUrgencyFilter === 'Todas' }">
        Todas
      </v-btn>
      <v-btn text @click="filterByUrgency('Urgente')" :class="{ active: activeUrgencyFilter === 'Urgente' }">
        Urgente
      </v-btn>
      <v-btn text @click="filterByUrgency('Importante')" :class="{ active: activeUrgencyFilter === 'Importante' }">
        Importante
      </v-btn>
      <v-btn text @click="filterByUrgency('Normal')" :class="{ active: activeUrgencyFilter === 'Normal' }">
        Normal
      </v-btn>
    </div>
  </v-toolbar>
  
          <!-- Lista de Notificações -->
          <v-list>
            <transition-group name="fade">
              <v-list-item
                v-for="(notification, index) in filteredNotifications"
                :key="index"
                class="notification-item"
                @mouseover="markAsReadOnHover(index)"
              >
                <!-- Círculo laranja para não lidas -->
                <!-- <div v-if="!notification.read" class="unread-indicator"></div> -->
  
                <!-- Ícone de notificação -->
                <v-list-item-icon>
                  <v-icon :color="getCategoryColor(notification.category)">
                    {{ getCategoryIcon(notification.category) }}
                  </v-icon>
                </v-list-item-icon>
  
                <!-- Conteúdo da Notificação -->
                <v-list-item-content>
                  <v-list-item-title class="notification-title">
                    <a :href="notification.link" @click="markAsRead(index)" class="notification-link">
                      {{ notification.message }}
                    </a>
                  </v-list-item-title>
                  <v-list-item-subtitle class="notification-time">
                    {{ notification.time }}
                  </v-list-item-subtitle>
                  <!-- Tag de Categoria -->
                  <div class="notification-tags">
                    <v-chip small :color="getCategoryColor(notification.category)">
                      {{ notification.category }}
                    </v-chip>
                  </div>
                </v-list-item-content>

                                <!-- Ícone de prioridade no canto superior direito -->
                                <v-icon
                                class="priority-icon"
                                :color="getPriorityColor(notification.priority)"
                                v-tooltip="{ content: notification.priority, placement: 'bottom' }"
                              >
                                {{ getPriorityIcon(notification.priority) }}
                              </v-icon>
  
                <!-- Ícones de leitura -->
                <div class="read-status">
                    <v-tooltip v-if="notification.readBy.length > 0" placement="left" top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          :color="notification.readBy.length === notification.totalReaders ? 'blue' : 'grey'"
                        >
                          mdi-check-all
                        </v-icon>
                      </template>
                      <span>
                        Lido por: {{ notification.readBy.join(', ') }}
                      </span>
                    </v-tooltip>
                    <v-icon
                      v-else
                      color="grey"
                      v-tooltip="'Não lido por ninguém'"
                    >
                      mdi-check
                    </v-icon>
                  </div>
              </v-list-item>
            </transition-group>
          </v-list>
  
          <!-- Rodapé -->
          <v-card-actions class="notification-footer">
            <v-btn text color="primary" @click="markAllAsRead">
              MARCAR TODAS COMO LIDAS
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>
  </template>
  
  <script setup>
  import { ref, computed } from 'vue';
  import {
  useVuetify,
} from "@/composables";
  
const vuetify = useVuetify();

  // Estado do dropdown
  const showNotifications = ref(false);
  const x = ref(0);
  const y = ref(0);
  
  // Filtros ativos
  const activeFilter = ref('Todos');
  const activeUrgencyFilter = ref('Todas');
  
  // Dados mockados das notificações
  const notifications = ref([
  {
    message: 'Matheus iniciou o embarque do evento Moura - Sonorização',
    link: '/event/1',
    time: 'Há 10 minutos',
    read: false,
    readBy: [], // Lista de usuários que leram
    totalReaders: 3, // Total de usuários que devem ler
    category: 'Eventos',
    priority: 'Normal',
  },
  {
    message: 'Lista técnica MAGALU alterada por Jose Augusto',
    link: '/event/2',
    time: 'Há 1 hora',
    read: false,
    readBy: ['Carlos Souza'], // Lista de usuários que leram
    totalReaders: 3,
    category: 'Eventos',
    priority: 'Urgente',
  },
  {
    message: 'Nova versão do sistema disponível! Pressione Shift + F5 para atualizar.',
    link: '/system/1',
    time: 'Há 2 horas',
    read: true,
    readBy: ['Admin', 'Suporte'], // Lista de usuários que leram
    totalReaders: 2,
    category: 'Sistema',
    priority: 'Importante',
  },
  {
    message: 'Evento LIVE finalizado!',
    link: '/live/1',
    time: 'Agora',
    read: false,
    readBy: [],
    totalReaders: 5,
    category: 'Live',
    priority: 'Urgente',
  },
  {
    message: 'Evento vídeo iniciado!',
    link: '/video/1',
    time: 'Há 30 minutos',
    read: false,
    readBy: [],
    totalReaders: 4,
    category: 'Video',
    priority: 'Importante',
  },
]);
  
  // Contador de notificações não lidas
  const unreadCount = computed(() => {
    return notifications.value.filter((n) => !n.read).length;
  });
  
  const isDark = computed(() => vuetify.theme.dark);

  // Notificações filtradas
  const filteredNotifications = computed(() => {
    let filtered = notifications.value;
  
    if (activeFilter.value !== 'Todos') {
      filtered = filtered.filter((n) => n.category === activeFilter.value);
    }
  
    if (activeUrgencyFilter.value !== 'Todas') {
      filtered = filtered.filter((n) => n.priority === activeUrgencyFilter.value);
    }
  
    return filtered;
  });
  
  // Alternar visibilidade do dropdown
  const toggleNotifications = (event) => {
    showNotifications.value = !showNotifications.value;
    if (showNotifications.value) {
      x.value = event.clientX;
      y.value = event.clientY;
    }
  };
  
  // Marcar notificação como lida
  const markAsRead = (index) => {
    notifications.value[index].read = true;
    if (!notifications.value[index].readBy.includes('Usuário Atual')) {
      notifications.value[index].readBy.push('Usuário Atual');
    }
  };
  
  // Marcar todas as notificações como lidas
  const markAllAsRead = () => {
    notifications.value.forEach((n) => {
      n.read = true;
      if (!n.readBy.includes('Usuário Atual')) {
        n.readBy.push('Usuário Atual');
      }
    });
  };
  
  // Marcar como lida ao passar o mouse
  const markAsReadOnHover = (index) => {
    if (!notifications.value[index].read) {
      markAsRead(index);
    }
  };
  
  // Filtrar por categoria
  const filterByCategory = (category) => {
    activeFilter.value = category;
  };
  
  // Filtrar por urgência
  const filterByUrgency = (urgency) => {
    activeUrgencyFilter.value = urgency;
  };
  
  // Cores das categorias
// Cores das categorias
const getCategoryColor = (category) => {
  switch (category) {
    case 'Eventos':
      return 'blue';
    case 'Sistema':
      return 'green';
    case 'Live':
      return 'purple';
    case 'Video':
      return 'red';
    default:
      return 'grey';
  }
};

// Ícones das categorias
const getCategoryIcon = (category) => {
  switch (category) {
    case 'Eventos':
      return 'mdi-calendar';
    case 'Sistema':
      return 'mdi-cog';
    case 'Live':
      return 'mdi-video';
    case 'Video':
      return 'mdi-video-box';
    default:
      return 'mdi-information';
  }
};
  
  // Cores e ícones de prioridade
  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'Urgente':
        return 'red';
      case 'Importante':
        return 'orange';
      default:
        return 'blue';
    }
  };
  
  const getPriorityIcon = (priority) => {
    switch (priority) {
      case 'Urgente':
        return 'mdi-alert';
      case 'Importante':
        return 'mdi-alert-circle';
      default:
        return 'mdi-information';
    }
  };
  </script>
  
  <style scoped>
  /* Estilos anteriores mantidos */
  .notification-btn {
    position: relative;
  }
  
  .notification-card {
    width: 400px;
    max-height: 500px;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .notification-item {
    border-bottom: 1px solid #eee;
    padding: 16px;
    position: relative;
  }
  
  .notification-item:last-child {
    border-bottom: none;
    position: relative;
  }
  
  .unread-indicator {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 10px;
    height: 10px;
    background-color: #ff6f00;
    border-radius: 50%;
    box-shadow: 0 0 8px #ff6f00;
    animation: breathing 2s infinite;
  }
  
  @keyframes breathing {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .notification-title {
    white-space: normal;
    word-wrap: break-word;
    line-height: 1.5;
    margin-bottom: 8px;
  }
  
  .notification-link {
    text-decoration: none;
    color: #333;
    font-weight: 500;
  }
  
  .notification-link:hover {
    text-decoration: underline;
    color: #1976d2;
  }
  
  .notification-time {
    color: #777;
    font-size: 0.875rem;
  }
  
  .notification-tags {
    margin-top: 8px;
  }
  
  .notification-footer {
    border-top: 1px solid #eee;
    padding: 8px 16px;
    display: flex;
    justify-content: flex-end;
  }
  
  .notification-card::-webkit-scrollbar {
    width: 8px;
  }
  
  .notification-card::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .notification-card::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .notification-card::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .filter-toolbar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #eee;
  }
  
  .urgency-toolbar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #eee;
  }
  
  .filter-toolbar .v-btn,
  .urgency-toolbar .v-btn {
    text-transform: capitalize;
  }
  
  .filter-toolbar .v-btn.active,
  .urgency-toolbar .v-btn.active {
    font-weight: bold;
    color: #1976d2;
  }
  
  .priority-icon {
    position: absolute;
    bottom: 16px;
    left: 16px;
  }
  
  .read-status {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }

  /* Estilos para os novos tipos */
.notification-item.live {
    border-left: 4px solid purple;
  }
  
  .notification-item.video {
    border-left: 4px solid red;
  }

  .filter-scroll-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap; /* Impede que as abas quebrem linha */
    padding-bottom: 8px; /* Espaço para o scroll */
  }
  
  .filter-scroll-container::-webkit-scrollbar {
    height: 6px;
  }
  
  .filter-scroll-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .filter-scroll-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .filter-scroll-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Estilos para o tema escuro */
.dark-theme {
    background-color: #1e1e1e;
    color: #ffffff;
  }
  
  .dark-theme .notification-item {
    border-bottom: 1px solid #333;
  }
  
  .dark-theme .notification-title {
    color: #ffffff;
  }
  
  .dark-theme .notification-time {
    color: #b0b0b0;
  }
  
  .dark-theme .notification-link {
    color: #ffffff;
  }
  
  .dark-theme .notification-link:hover {
    color: #90caf9;
  }
  
  .dark-theme .filter-toolbar,
  .dark-theme .urgency-toolbar {
    background-color: #2d2d2d;
    border-bottom: 1px solid #333;
  }
  
  .dark-theme .v-btn {
    color: #ffffff;
  }
  
  .dark-theme .v-btn.active {
    color: #90caf9;
  }
  
  .dark-theme .notification-card::-webkit-scrollbar-track {
    background: #2d2d2d;
  }
  
  .dark-theme .notification-card::-webkit-scrollbar-thumb {
    background: #555;
  }
  
  .dark-theme .notification-card::-webkit-scrollbar-thumb:hover {
    background: #777;
  }
  </style>